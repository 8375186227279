<template>
  <common-container  title="AI数据统计图" class="left-bottom"  style="margin-top: 0%">
    <div class="bar" id="barpic" style="height: 100%;width: 100%;"></div>
  </common-container>
</template>
<script>
import CommonContainer from "V/lotBigData/components/commonContainer/Index";
let echarts = require('echarts/lib/echarts')
import 'echarts/lib/chart/bar'
import {getAllIotTypeevent, getEventListCountByCondition} from "A/wlw";
import {getCache} from "U/index";
require('echarts/lib/component/tooltip')
require('echarts/lib/component/title')
export default {
  name:'LeftBottom',
  components: {CommonContainer},
  props:{
    SelectedArea:{
      default:""
    },
    SelectedPlace:{
      default:""
    },
    SelectedLiftyear:{
      default:""
    },
    SelectedDevicemodelid:{
      default:""
    },
  },
  data() {
    return {
      areacode:'',
      myChart:null,
      dataArr:['','',''],
    }
  },
  created(){
    this.initOptions();
  },
  mounted () {
    const resize = ()=>{
      setTimeout(()=>{
        if(this.myChart){this.myChart.resize()}
      })
    }
    window.addEventListener('resize',resize)
    this.$once('hook:beforeDestroy',()=>{
      window.removeEventListener('resize',resize)
    })
  },
  watch:{
    'SelectedArea':function (){
      this.initOptions()
    },
    'SelectedPlace':function (){
      this.initOptions()
    },
    'SelectedLiftyear':function (){
      this.initOptions()
    },
    'SelectedDevicemodelid':function (){
      this.initOptions()
    },
  },
  methods:{
    initOptions() {
      // console.log(this.myChart)
      // if(this.myChart!=null){
      //   this.myChart.dispose();
      // }
      const p_option = {
        tooltip: {
          trigger: 'axis',
        },
        grid: {
          top: '10%',
          left: '1%',
          right: '10',
          bottom: '0%',
          containLabel: true
        },
        xAxis: {
          // "电瓶车进入轿厢", "轿厢有人长时间驻留", "长时间挡门"
          data: [],
          axisLabel: {
            interval: 0,
            formatter: function (value) {
              var ret = "";
              //拼接加\n返回的类目项
              var maxLength = 5;
              //每项显示文字个数
              var valLength = value.length;
              //X轴类目项的文字个数
              var rowN = Math.ceil(valLength / maxLength);
              //类目项需要换行的行数
              if (rowN > 1)
                  //如果类目项的文字大于3,
              {
                for (var i = 0; i < rowN; i++) {
                  var temp = "";
                  //每次截取的字符串
                  var start = i * maxLength;
                  //开始截取的位置
                  var end = start + maxLength;
                  //结束截取的位置//这里也可以加一个是否是最后一行的判断，但是不加也没有影响，那就不加吧
                  temp = value.substring(start, end) + "\n";
                  ret += temp; //凭借最终的字符串
                }
              } else {
                ret = value;
              }
              return ret;
            },
            // rotate:40
            // textStyle: {
              color: '',
              fontSize: '9',
              itemSize: ''
            // }
          },
        },
        yAxis: {},
        series: [{
          name: 'AI事件',
          type: 'bar',
          data: this.dataArr,
          // label: {
          //   show: true, // 开启显示
          //   position: 'top', // 在上方显示
          //   distance: 20, // 距离图形元素的距离。当 position 为字符描述值（如 'top'、'insideRight'）时候有效。
          //   verticalAlign: 'middle',
          //   textStyle: { // 数值样式
          //     color: '#999999',
          //     fontSize: 12
          //   }
          // }
        }]
      }
      let logininfo = getCache('logininfo', true);
      if (logininfo) {
        this.usertype = logininfo.usertype;
        this.areacode = logininfo.areacode;
      }
      let params = {
        areacode: this.SelectedArea=="0"?"":this.SelectedArea,
        placeid: this.SelectedPlace=="0"?"":this.SelectedPlace,
        liftyear: this.SelectedLiftyear=="0"?"":this.SelectedLiftyear,
        terminaltypeid: this.SelectedDevicemodelid=="0"?"":this.SelectedDevicemodelid,
        eventtype: 3
      }
      getEventListCountByCondition(params).then(res => {
        if (res && res.returncode == '0') {
          console.log(res.item.length)
          if(res.item.length>0){
            for (var i = 0; i < res.item.length; i++) {
              p_option.xAxis.data[i] = res.item[i].eventname
              this.dataArr[i] = res.item[i].num
              // this.dataArr[0] = res.item[0].num
              // this.dataArr[1] = res.item[1].num
              // this.dataArr[2] = res.item[2].num
            }
          }else{
            p_option.xAxis.data = ["","",""]
            this.dataArr[0]='0';
            this.dataArr[1]='0';
            this.dataArr[2]='0';
          }
        }
      })
      setTimeout(() => {
        this.initData(p_option)
      }, 2500);
    },
     //初始化数据
    initData(p_option) {
      // 基于准备好的dom，初始化echarts实例
      this.myChart = echarts.init(document.getElementById('barpic'));
      // 绘制图表
      this.myChart.hideLoading();
      // console.log(p_option)
      this.myChart.setOption(p_option);
    },
  }
}
</script>
<style lang="scss" scoped>
.bar{
  pointer-events: auto;
  //margin-bottom: -15%;
}
.left-bottom{
  /*margin-top: 25%;*/
  //margin-top: -4%;
  float: left;
  width: 100%!important;
  height: 30%;
  //overflow: hidden;
}
</style>

<!--<template>-->
<!--  <common-container  title="近六个月接警处置数" class="bottom-six">-->
<!--    <BarCharts id="jlgyjcczs" :lists="datas" types="vertial" outColor="#0072ff" />-->
<!--  </common-container>-->
<!--</template>-->

<!--<script>-->
<!--  import CommonContainer from "@/views/lotBigData/components/commonContainer/Index";-->
<!--  import BarCharts from "@/views/lotBigData/components/barCharts/Index";-->
<!--  // import { mapState } from 'vuex'-->
<!--  // import { getIotPlaceevent } from "@/api/wlw";-->
<!--  export default{-->
<!--    name:'LeftBottom',-->
<!--    components: {CommonContainer,BarCharts},-->
<!--    // computed: {-->
<!--    //   ...mapState({-->
<!--    //     wlwDate: state => state.main.wlwDate,-->
<!--    //     areacode: state => state.main.areacode,-->
<!--    //   })-->
<!--    // },-->
<!--    data() {-->
<!--      return {-->
<!--        // datas:null-->
<!--        datas:[-->
<!--          {key:'9月',value:'300'},-->
<!--          {key:'10月',value:'160'},-->
<!--          {key:'11月',value:'110'},-->
<!--          {key:'12月',value:'260'},-->
<!--          {key:'1月',value:'180'},-->
<!--          {key:'2月',value:'220'},-->
<!--        ]-->
<!--      }-->
<!--    },-->
<!--    mounted () {-->
<!--      // this.getDatas();-->
<!--    },-->
<!--    methods: {-->
<!--      getDatas() {-->
<!--        // if(!this.wlwDate){return}-->
<!--        // getIotPlaceevent({-->
<!--        //   areacode:this.areacode,-->
<!--        //   starttime:this.wlwDate-->
<!--        // }).then(res=>{-->
<!--        //   if(res.returncode==="0"){-->
<!--        //     this.datas = res.item.map(v=>({-->
<!--        //       key:v.value,-->
<!--        //       value:v.num-->
<!--        //     }))-->
<!--        //   }-->
<!--        // })-->
<!--      }-->
<!--    },-->
<!--  }-->
<!--</script>-->

<!--<style lang="scss" scoped>-->
<!--  .bottom-six{-->
<!--    width: 100%;-->
<!--    height: 27%;-->
<!--  }-->
<!--</style>-->